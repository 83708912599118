<template>
  <div class="LogOut"/>
</template>

<script>
import { auth } from '@/firebase/init'

export default {
  name: 'LogOut',
  mounted() {
    auth.signOut()

    setInterval(() => {
      this.$router.push({ name: 'Home' })
    }, 250)
  },
}
</script>

<style lang="stylus" scoped>
  .LogOut
    display block
</style>

